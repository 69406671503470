<template>
    <el-tabs v-model="educationTab" type="card" class="education-content tabs-full" @tab-click="toggleEducationTab">
        <el-tab-pane label="班级管理" name="classManage">
            <ClassManage ref="classManage" />
        </el-tab-pane>
        <el-tab-pane label="学生管理" name="studentManage">
            <StudentManage v-if="role === 1" ref="studentManage" />
            <TrainStudentManage v-else-if="role === 2" ref="studentManage" />
        </el-tab-pane>
        <el-tab-pane label="评委管理" name="teacherManage" v-if="role === 1">
            <TeacherManage ref="teacherManage" />
        </el-tab-pane>
    </el-tabs>
</template>

<script>
    import ClassManage from "../../components/schoolAdmin/educationManage/ClassManage";
    import StudentManage from "../../components/schoolAdmin/educationManage/StudentManage.vue";
    import TrainStudentManage from "../../components/schoolAdmin/educationManage/TrainStudentManage.vue";
    import TeacherManage from "../../components/schoolAdmin/educationManage/TeacherManage";
    export default {
        data() {
            return {
                educationTab: 'classManage',
                role: Number(localStorage.getItem('role')),
            }
        },
        components: {
            TeacherManage,
            StudentManage,
            TrainStudentManage,
            ClassManage,
        },
        mounted() {

        },
        methods: {
            toggleEducationTab(tab) {
                if (tab.name === 'classManage') { // 班级管理
                    this.$refs.classManage.getClassList()
                } else if (tab.name === 'studentManage') { // 学生管理
                    if (this.role === 1) { // 学校管理员
                        this.$refs.studentManage.getClassList()
                        this.$refs.studentManage.getStudentList()
                    } else { // 训练管理员
                        this.$refs.studentManage.getClassList()
                    }
                } else if (tab.name === 'teacherManage') { // 评委管理
                    this.$refs.teacherManage.getTeacherList()
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .education-content {
        height: 100%;
    }
</style>