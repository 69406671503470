<template>
    <div class="education-wrapper">
        <div class="education-header">
            <div class="select">
                班级筛选：
                <el-select v-model="chooseClass" placeholder="选择班级" @change="toggleClass" clearable style="width: 200px">
                    <el-option v-for="classItem in classList" :key="`classList_${classItem.name}`" :label="classItem.name" :value="classItem.id"></el-option>
                </el-select>
                <el-button class="btn-blue-line-all" @click="closeMulBtn" style="margin-left: 10px;">关闭</el-button>
                <el-button class="btn-blue" @click="openMulBtn">开启</el-button>
            </div>
            <div>
                <el-button class="btn-blue-line-all" @click="initializationData">初始化数据</el-button>
                <el-button class="btn-blue-line-all" @click="downloadBtn">下载模板</el-button>
                <el-button class="btn-blue" @click="importBtn">导入学生</el-button>
                <input type="file" ref="excelInput" accept=".csv, .xls, .xlsx" title="" @change="changeExcel($event)" style="display: none;">
                <el-button class="btn-blue" @click="addBtn" style="margin-left: 10px">创建学生</el-button>
            </div>
        </div>
        <el-table :data="studentList" border style="width: 100%; margin-top: 20px; flex: 1" height="1%" size="medium" @selection-change="selectChange"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#13131B', background: '#F2F3FA'}"
                  :cell-style="{fontSize: '14px',color: '#343441'}">
            <el-table-column type="selection" width="55" align="center" :selectable="getCheckList"></el-table-column>
            <el-table-column prop="name" align="center" label="学生名称"></el-table-column>
            <el-table-column align="center" label="账号状态">
                <template slot-scope="scope">
                    {{scope.row.account_type === 0 ? '已关闭' : scope.row.account_type === 1 ? '已开启' : '暂无训练'}}
                </template>
            </el-table-column>
            <el-table-column prop="username" align="center" label="账号"></el-table-column>
            <el-table-column align="center" label="头像">
                <template slot-scope="scope">
                    <div class="school-logo">
                        <img :src="scope.row.headimgurl ? scope.row.headimgurl : require('../../../assets/images/user_img.png')" alt="">
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="class_name" align="center" label="班级"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link :underline="false" class="link-blue" @click="editBtn(scope.row)">编辑</el-link>
                    <el-link :underline="false" type="danger" @click="delBtn(scope.row.id)">删除</el-link>
                    <el-link :underline="false" type="danger" @click="closeBtn(scope.row)" v-if="scope.row.account_type === 1">关闭</el-link>
                    <el-link :underline="false" class="link-blue" @click="openBtn(scope.row)" v-else-if="scope.row.account_type === 0">开启</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pager-center" style="margin-top: 10px;"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       :total="listPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="pagesCurrentChange">
        </el-pagination>

        <!--        添加编辑弹窗-->
        <el-dialog :title="dialogTitle" :visible.sync="addDialog" width="500px" custom-class="dialog-blue"
                   @close="resetForm()" @opened="openDialog" :close-on-click-modal="false">
            <el-form :model="addForm" ref="addForm" :rules="addRules" label-position="right" label-width="100px">
                <el-form-item label="学生名称" prop="name">
                    <el-input v-model="addForm.name" ref="cursorInput" autocomplete="off" placeholder="2-20字"></el-input>
                </el-form-item>
                <el-form-item label="账号" prop="username">
                    <el-input v-model="addForm.username" autocomplete="off" placeholder="4-20字"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password" v-if="distinguishBtn === 'add'">
                    <el-input v-model="addForm.password" auto-complete="new-password" minlength="6" maxlength="20" show-password placeholder="6-20字"></el-input>
                </el-form-item>
                <el-form-item label="重置密码" prop="updatePassword" v-else-if="distinguishBtn === 'edit'">
                    <el-input v-model="addForm.password" auto-complete="new-password" minlength="6" maxlength="20" show-password placeholder="6-20字"></el-input>
                </el-form-item>
                <el-form-item label="所属班级" prop="class_id">
                    <el-select v-model="addForm.class_id" placeholder="所属班级" clearable style="width: 100%" @change="getClassName">
                        <el-option v-for="classItem in classList" :key="`classList_${classItem.id}`" :label="classItem.name" :value="classItem.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="头像"  style="margin-bottom: 0">
                    <el-upload class="logo-uploader"
                               :action="uploadCover"
                               :headers="headersParams"
                               name="file"
                               :show-file-list="false"
                               :on-success="handleCoverSuccess"
                               :before-upload="beforeCoverUpload">
                        <img v-if="imgSrc" :src="imgSrc" class="logo-cover">
                        <i v-else class="el-icon-plus logo-uploader-icon"></i>
                    </el-upload>
                    <span class="school-prompt">建议尺寸：200*200；格式为.jpg 或 .jpeg 或 .png</span>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer" style="text-align: center">
                <el-button size="medium" class="btn-blue" @click="addToForm('addForm')">确 定</el-button>
                <el-button size="medium" @click="addDialog = false">取 消</el-button>
            </div>
        </el-dialog>
        <!--初始化数据弹窗-->
        <el-dialog
                title="初始化数据"
                :visible.sync="initializationDialog"
                :close-on-click-modal="false"
                @close="resetDialog()"
                width="508px"
                class="initialization-dialog">
            <div class="train-dialog-content">
                <el-form :model="initializationForm" ref="initializationForm" :rules="initializationRules" label-position="right" label-width="100px">
                    <el-form-item label="训练名称" prop="com_id">
                        <el-select v-model="initializationForm.com_id" clearable style="width: 100%">
                            <el-option v-for="trainItem in TrainList" :key="trainItem.id" :label="trainItem.name" :value="trainItem.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="初始化数据" prop="type">
                        <el-select v-model="initializationForm.type" clearable style="width: 100%" @change="getType">
                            <el-option v-for="typeItem in typeList" :key="typeItem.type" :label="typeItem.name" :value="typeItem.type"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="initializationDialog = false">取 消</el-button>
                <el-button type="primary" @click="initializationBtn('initializationForm')" class="btn-blue">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import _ from "underscore";
    export default {
        data() {
            return {
                chooseClass: null,
                classList: [],
                studentList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                // S 添加弹窗
                dialogTitle: '',
                addDialog: false,
                distinguishBtn: '',
                addForm: {
                    id: '',
                    name: '',
                    username: '',
                    password: '',
                    headimgurl: '',
                    class_id: '',
                    class_name: '',
                },
                addRules: {
                    name: [
                        { required: true, message: '请输入学生名称', trigger: 'blur' },
                        { min: 2, max: 20, message: '长度在 2 到 20 个字', trigger: 'blur' }
                    ],
                    username: [
                        { required: true, message: '请输入学生账号', trigger: 'blur' },
                        { min: 4, max: 20, message: '长度在 4 到 20 个字', trigger: 'blur' }
                    ],
                    password: [
                        { required: true, message: '请输入学生密码', trigger: 'blur' },
                        { min: 6, max: 20, message: '长度在 6 到 20 个字', trigger: 'blur' }
                    ],
                    updatePassword: [
                        { min: 6, max: 20, message: '长度在 6 到 20 个字', trigger: 'blur' }
                    ],
                    class_id: [
                        { required: true, message: '请选择班级', trigger: 'change' },
                    ],
                },
                // E 添加弹窗
                // S 上传图片需要
                uploadCover: this.$api.studentAdminUploadHead,
                headersParams: {
                    Authorization: localStorage.getItem('token')
                },
                imgSrc: null,
                // E 上传图片需要
                // 选择学生list
                selectArr: [],
                //初始化数据弹窗
                initializationDialog: false,
                initializationForm: {
                    com_id: '',
                    type: ''
                },
                initializationRules: {
                    com_id: [
                        { required: true, message: '请选择训练名称', trigger: 'change' }
                    ],
                    type: [
                        { required: true, message: '请选择初始化数据', trigger: 'change' }
                    ]
                },
                //训练名称列表
                TrainList: [],
                //初始化数据列表
                typeList: [
                    {
                        type: '1',
                        name: '班级数据'
                    },
                    {
                        type: '2',
                        name: '学生数据'
                    },
                    {
                        type: '3',
                        name: '学生店铺装修数据'
                    },
                    {
                        type: '4',
                        name: '学生店铺选品数据'
                    },
                    {
                        type: '5',
                        name: '学生店铺营销数据'
                    },
                    {
                        type: '6',
                        name: '学生店铺引流数据'
                    },
                    {
                        type: '7',
                        name: '学生客户服务数据'
                    }
                ],
                typeName: '',
                hostUrl:''
            }
        },
        mounted() {
            this.getClassList()
            // this.getStudentList()
        },
        methods: {
            // 获取班级列表
            getClassList() {
                this.$http.axiosGet(this.$api.collegeClass, (res) => {
                    if (res.code === 200) {
                        this.classList = res.data.data
                        this.toggleClass(this.classList[0].id)
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 切换班级
            toggleClass(val) {
                this.chooseClass = val
                this.getStudentList()
            },
            // 获取学生列表
            getStudentList() {
                let params = {
                    paging: 1,
                    page: this.listPages.currentPageNum,
                    pageSize: this.listPages.eachPageNum,
                }
                if (this.chooseClass) {
                    params.class_id = this.chooseClass
                }
                this.$http.axiosGetBy(this.$api.studentAdminList, params,(res) => {
                    if (res.code === 200) {
                        this.studentList = res.data.data
                        this.listPages.total = res.data.total
                        this.hostUrl = res.data.hostUrl
                        if (this.listPages.total !== 0 && this.studentList.length === 0) {
                            this.listPages.currentPageNum--;
                            this.getStudentList();
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 切换分页
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getStudentList()
            },
            // 打开弹窗光标
            openDialog() {
                this.$refs.cursorInput.focus();
            },
            // 获取学校名称
            getClassName(val) {
                let obj = {};
                obj = this.classList.find((item) => {
                    return item.id === val
                })
                this.addForm.class_name = obj.name
            },
            // 下载模板
            downloadBtn() {
                window.location.href = this.hostUrl + 'uploads/excel/educaion/student_model.xlsx';
            },
            // 导入学生
            importBtn() {
                this.$refs.excelInput.click();
            },
            changeExcel(event) {
                if (event.target.files.length === 0) return;
                if (event.target.files[0].size > 1024 * 1024 * 200) {
                    this.$message.warning('文件大小不得超过200M');
                    return;
                }
                let fileList = event.target.files[0];
                let formData = new FormData();
                formData.append('excel', fileList);
                this.$http.axiosPost(this.$api.studentAdmin_excel, formData, (res) => {
                    if (res.code === 200) {
                        this.$message.success(res.msg)
                        this.getStudentList()
                    } else {
                        this.$message.warning(res.msg);
                    }
                    this.$refs.excelInput.value = ''
                }, (err) => {
                    console.log(err);
                    this.$refs.excelInput.value = ''
                })
            },
            // 添加
            addBtn() {
                this.dialogTitle = '创建学生'
                this.distinguishBtn = 'add'
                this.addDialog = true
            },
            // 编辑
            editBtn(row) {
                console.log('row', row)
                this.$http.axiosGetBy(this.$api.studentAdminList, {id: row.id}, (res) => {
                    console.log('编辑中', res)
                    if (res.code === 200) {
                        this.addForm.headimgurl = res.data.headimgurl // 无域名
                        this.imgSrc = res.data.headimgscr // 有域名
                        // this.imgSrc = res.data.headimgurl // 无域名
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
                this.addForm.id = row.id;
                this.addForm.name= row.name;
                this.addForm.username = row.username;
                this.addForm.class_id = row.class_id;
                this.addForm.class_name = row.class_name;
                this.dialogTitle = '编辑学生'
                this.distinguishBtn = 'edit'
                this.addDialog = true
            },
            // start 上传图片
            beforeCoverUpload(file) {
                const isImg = file.name.toLowerCase().endsWith('.jpg') || file.name.toLowerCase().endsWith('.jpeg') || file.name.toLowerCase().endsWith('.png');
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isImg) {
                    this.$message.error('上传图片只能是 jpg 或 jpeg 或 png 格式!');
                    return false;
                }
                if (!isLt2M) {
                    this.$message.error('上传头像大小不能超过 2MB!');
                }
                return isImg && isLt2M;
            },
            handleCoverSuccess(res, file) {
                if (res.code === 200) {
                    this.imgSrc = res.data.src; // 有域名
                    this.addForm.headimgurl = res.data.url; //无域名
                    this.$message.success('头像上传成功')
                } else {
                    this.$message.error('头像上传失败，请稍后再试~')
                }
            },
            // end 上传图片
            // 添加表单确定
            addToForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.distinguishBtn === 'add') {
                            let formData = new FormData();
                            formData.append('name', this.addForm.name);
                            formData.append('username', this.addForm.username);
                            formData.append('password', this.addForm.password);
                            formData.append('headimgurl', this.addForm.headimgurl);
                            formData.append('class_id', this.addForm.class_id);
                            formData.append('class_name', this.addForm.class_name);
                            this.$http.axiosPost(this.$api.studentAdminAdd, formData, (res) => {
                                if (res.code === 200) {
                                    this.$message.success(res.msg)
                                    this.listPages.currentPageNum = 1
                                    this.getStudentList()
                                    this.addDialog = false
                                    this.resetForm()
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        } else if (this.distinguishBtn === 'edit') {
                            let formData = new FormData();
                            formData.append('name', this.addForm.name);
                            formData.append('username', this.addForm.username);
                            formData.append('headimgurl', this.addForm.headimgurl);
                            formData.append('class_id', this.addForm.class_id);
                            formData.append('class_name', this.addForm.class_name);
                            formData.append('id', this.addForm.id);
                            if (this.addForm.password) {
                                formData.append('password', this.addForm.password);
                            }
                            this.$http.axiosPost(this.$api.studentAdminUpdate, formData, (res) => {
                                if (res.code === 200) {
                                    this.$message.success(res.msg)
                                    this.getStudentList()
                                    this.addDialog = false
                                    this.resetForm()
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            // 删除
            delBtn(id) {
                this.$confirm('是否删除该学生，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-blue',
                    type: 'warning',
                }).then(() => {
                    this.$http.axiosPost(this.$api.studentAdminDel, {id: id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg)
                            this.getStudentList();
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },
            // 关闭学生训练
            closeBtn(row) {
                this.$confirm('是否关闭该学生的训练', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-blue',
                    type: 'warning',
                }).then(() => {
                    let params = {
                        s_id: row.id,
                        status: 0
                    }
                    console.log('params', params)
                    this.$http.axiosGetBy(this.$api.tm_openOrCloseStu, params, (res) => {
                        console.log('关闭', res)
                        if (res.code === 200) {
                            this.$message.success(res.msg)
                            this.getStudentList()
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消关闭')
                });
            },
            // 开启学生训练
            openBtn(row) {
                this.$confirm('是否开启该学生的训练', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-blue',
                    type: 'warning',
                }).then(() => {
                    let params = {
                        s_id: row.id,
                        status: 1
                    }
                    console.log('params', params)
                    this.$http.axiosGetBy(this.$api.tm_openOrCloseStu, params, (res) => {
                        console.log('开启', res)
                        if (res.code === 200) {
                            this.$message.success(res.msg)
                            this.getStudentList()
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消开启')
                });
            },
            // 选择学生list
            selectChange(selection) {
                let arrTmp = []
                selection.forEach(item => {
                    arrTmp.push(item.id)
                })
                this.selectArr = arrTmp
            },
            // 获取列表不可选的状态
            getCheckList(row, index) {
                if (row.account_type === 2) {
                    return false
                } else {
                    return true
                }
            },
            // 关闭多个学生训练
            closeMulBtn() {
                if (this.selectArr.length === 0) {
                    return this.$message.warning('请选择学生')
                }
                this.$confirm('是否关闭所选择的学生的训练', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-blue',
                    type: 'warning',
                }).then(() => {
                    let params = {
                        s_id: this.selectArr.join('#'),
                        status: 0
                    }
                    this.$http.axiosGetBy(this.$api.tm_openOrCloseStu, params, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg)
                            this.getStudentList()
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消关闭')
                });
            },
            // 开启多个学生训练
            openMulBtn() {
                if (this.selectArr.length === 0) {
                    return this.$message.warning('请选择学生')
                }
                this.$confirm('是否开启所选择的学生的训练', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-blue',
                    type: 'warning',
                }).then(() => {
                    let params = {
                        s_id: this.selectArr.join('#'),
                        status: 1
                    }
                    this.$http.axiosGetBy(this.$api.tm_openOrCloseStu, params, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg)
                            this.getStudentList()
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消开启')
                });
            },
            // 重置表单
            resetForm() {
                this.addForm = {
                    id: '',
                    name: '',
                    username: '',
                    password: '',
                    headimgurl: '',
                    class_id: '',
                    class_name: '',
                }
                this.imgSrc = null
            },
            //初始化数据
            initializationData() {
                this.initializationDialog = true;
                this.getStudentTrainList();
            },
            initializationBtn(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.initializationForm.type !== '1' && this.selectArr.length === 0) {
                            return this.$message.warning('请选择要初始化的学生')
                        }
                        this.$confirm(`是否确定初始化${this.typeName}`, '提示消息', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            confirmButtonClass: 'btn-blue',
                            type: 'warning',
                        }).then(() => {
                            let params = {
                                c_id: this.chooseClass,
                                com_id: this.initializationForm.com_id,
                                type: this.initializationForm.type
                            }
                            if (this.selectArr) {
                                params.stu_ids = this.selectArr.join('#')
                            }
                            this.$http.axiosGetBy(this.$api.data_initializeData, params, (res) => {
                                if (res.code === 200) {
                                    this.$message.success(res.msg);
                                    this.initializationDialog = false;
                                    this.resetDialog();
                                } else {
                                    this.$message.warning(res.msg);
                                }
                            }, (err) => {
                                console.log(err)
                            })
                        }).catch(() => {
                            this.$message.info('已取消关闭');
                            this.resetDialog();
                        });
                    }
                });
            },
            //获取训练名称列表
            getStudentTrainList() {
                let param = {
                    mode: 2,
                    status: 2,
                    class_id: Number(this.chooseClass)
                }
                this.$http.axiosGetBy(this.$api.comList, param, (res) => {
                    if (res.code === 200) {
                        this.TrainList = res.data;
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //选择要初始化的类型
            getType(val) {
                let trainTypeName = _.find(this.typeList, {type: val});
                this.typeName = trainTypeName.name;
            },
            //重置初始化弹窗
            resetDialog() {
                this.initializationForm = {
                    com_id: '',
                    type: ''
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .education-wrapper {
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .education-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .el-breadcrumb {
                line-height: 40px;
            }
        }
    }
    .el-link + .el-link {
        margin-left: 10px;
    }
    .school-logo {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .logo-uploader {
        width: 100px;
        height: 100px;
        border: 1px solid #ddd;
        ::v-deep .el-upload {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .logo-cover {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }
        .logo-uploader-icon {
            color: #ccc;
            font-size: 24px;
        }
    }
    .school-prompt {
        color: #F56C6C;
    }
    .initialization-dialog {
        ::v-deep .el-dialog__header {
            background-color: #0200D8;
            .el-dialog__title {
                color: #fff;
                font-size: 16px;
            }
            .el-dialog__headerbtn .el-dialog__close {
                color: #fff;
            }
        }
        ::v-deep .el-dialog__body {
            padding-bottom: 0;
        }
    }
</style>
